$iframe-width: 1300px;
$iframe-height: 500px;

.iframe-container {
  transform: scale(0.75);
  width: $iframe-width;
  height: $iframe-height;
  display: flex;
  align-items: center;
  justify-content: center;
}

#scaled-frame {
  width: $iframe-width;
  height: $iframe-height;
  border: 1px solid;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  #scaled-frame {
    zoom: 1;
  }
}

.not-loaded {
  display: none;
}
