// VARIABLES
@import "variables";

// DEPENDENCIES
@import "~react-datepicker/dist/react-datepicker.css";
@import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// OVERRIDES
@import "datepicker";
@import "../../views/CustomOffer/components/Steps/CustomOfferSteps/Description/components/VehicleDescription/index.scss";
@import "../../components/Modals/PreviewPublishModal/index.scss";
@import "../../components/Table/index.scss";
@import "../../components/LogsAsCode/index.scss";

body {
  overflow-x: hidden;
}