$black: #333333;
$white: #FFFFFF;


$primary-color: #FD5720;
$primary-color-light: #FEC2AE;

$secondary-color: #1678C2;
$secondary-color-light: #7EBFF1;

$gray-100: #FAFAFA;
$gray-200: #F0F0F0;
$gray-300: #98999F;
$gray-400: #4C4C4C;

$status-success: #50C878;
$status-warning: #FDD010;
$status-error: #EF6969;
