.vehicle-description {
  background-color: $gray-100;
  border: 1px solid $gray-200;
  border-radius: 4px;
  padding: 8px;

  .public-DraftStyleDefault-block {
    margin: 0;
  }
}
