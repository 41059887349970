// These styles came from Milimatch DatePicker implementation
// We're using this in order to make the DatePicker selector
// looks like a Chakra component.

.react-datepicker {
  font-family: "Lato Regular", sans-serif;
  overflow: hidden;

  &-wrapper {
    width: 100%;
  }
}

.react-datepicker-wrapper {
  width: 100%
}

.react-datepicker-popper {
  z-index: 999;
}

.react-datepicker__triangle {
  left: 10% !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background: $secondary-color-light;
  color: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background: $secondary-color-light;
  color: white;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background: $secondary-color !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background: $secondary-color;
}

// Fix: The month year dropdown doesn't scroll
.react-datepicker__month-year-dropdown {
  overflow: scroll;
  height: 200px;
}
