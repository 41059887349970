@import "variables";

pre {
  background: $black;
  padding: 1em;
  max-height: 600px;
}

code, pre {
  overflow: auto;
  border-radius: 0.3em;
  color: $white !important;
  font-size: 12px !important;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  tab-size: 2;
  hyphens: none;
}

code {
  background: transparent !important;
}