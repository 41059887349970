.progressBar {
  overflow: hidden;
  position: relative;
  background: var(--chakra-colors-gray-100);

  .progressBarColor {
    transition: all ease .3s;
    border-radius: 4px;

    &.progressBarColor--red {
      background-color: var(--chakra-colors-red-500);
    }

    &.progressBarColor--warning {
      background-color: var(--chakra-colors-warning-500);
    }

    &.progressBarColor--success {
      background-color: var(--chakra-colors-success-500);
    }
  }
}
